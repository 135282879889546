import SmartPhone from '../components/ui/smartPhone';

const baseUrl = 'https://public-file.wenow.me/';

const Item = ({ vodUrl, description }) => {
  return (
    <div className='p-4 bg-white rounded-xl flex items-center justify-center gap-4 sm:gap-8'>
      <SmartPhone
        height={380}
        width={200}
      >
        <img
          src={`${baseUrl}${vodUrl}`}
          alt='app'
          className='w-full h-full object-cover'
        />
      </SmartPhone>
      <div className='leading-none font-bold text-xl text-[#626c39] text-left sm:text-2xl'>
        {description.map((desc, i) => (
          <div key={i}>{desc}</div>
        ))}
      </div>
    </div>
  );
};

const AboutAppSection = () => {
  return (
    <section>
      <div className='p-4 bg-[#e5eccc] sm:p-8'>
        <div className='mb-4'>
          <h1 className='mb-2 text-3xl font-["Anton"] text-[#626c39]'>
            See what’s waiting inside...
          </h1>
          <p className='text-[#626c39]'>
            TheChamps offers tools to track and support every step of your
            fitness journey toward achieving your goals.
          </p>
        </div>
        <div className='space-y-4'>
          <Item
            vodUrl='davidpark/app/ai_scan.gif'
            description={['AI', 'Powered', 'Meal', 'Tracker']}
          />
          <Item
            vodUrl='davidpark/app/workout_plan.gif'
            description={['Tailored', 'Weekly', 'Workout', 'Plan']}
          />
          <Item
            vodUrl='davidpark/app/workout_tracker.gif'
            description={['Daily', 'Workout', 'Tracker']}
          />
          <Item
            vodUrl='davidpark/app/meal_tracker.gif'
            description={['Tailored', 'Meal Plan', '& Tracker']}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutAppSection;
