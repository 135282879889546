import { useEffect, useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Nav = () => {
  const [isTop, setIsTop] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpenMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition === 0) {
      setIsTop(true);
    } else if (scrollPosition > 64) {
      setIsTop(false);
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const goHome = () => {
    window.location.href = '/';
  };

  const goProgram = () => {
    window.location.href = '/#program';
  };

  const goAbout = () => {
    window.location.href = '/#about';
  };

  const goCalculator = () => {
    window.location.href = '/#calculator';
  };

  const goReviews = () => {
    window.location.href = '/#review';
  };

  const goFaq = () => {
    window.location.href = '/#faq';
  };

  const goApplication = () => {
    window.location.href = '/#application';
  };

  return (
    <div className={`${menuOpen ? 'overscroll-none' : ''}`}>
      <div
        className={`flex justify-between items-center px-4 py-2 fixed top-0 left-0 right-0 w-full max-w-3xl z-50 mx-auto transition duration-500 ease-in-out text-[#626c39]  ${
          isTop ? 'bg-transparent' : 'backdrop-blur-md'
        }`}
      >
        <div className='font-["Anton"] font-medium text-lg text-white'>
          TheChamps
        </div>
        <div className={`flex items-center justify-end`}>
          <Bars3Icon
            className={`flex-none h-6 hover:cursor-pointer transition duration-500 ease-in-out contrast-200`}
            onClick={handleOpenMenu}
          />
        </div>
      </div>
      {menuOpen && (
        <ul className='fixed inset-0 h-full max-w-7xl z-50 flex flex-col bg-[#626c39] text-white items-end gap-y-12 font-bold uppercase px-4 py-2 text-2xl mx-auto text-right'>
          <li>
            <XMarkIcon
              className='text-white h-6 transition-rotate duration-500 hover:rotate-90 cursor-pointer'
              onClick={handleOpenMenu}
            />
          </li>
          <li
            className='hover:cursor-pointer'
            onClick={() => {
              goHome();
              handleOpenMenu();
            }}
          >
            home
          </li>
          <li
            className='hover:cursor-pointer'
            onClick={() => {
              goAbout();
              handleOpenMenu();
            }}
          >
            about
          </li>
          <li
            className='hover:cursor-pointer'
            onClick={() => {
              goCalculator();
              handleOpenMenu();
            }}
          >
            calculator
          </li>
          <li
            className='hover:cursor-pointer'
            onClick={() => {
              goReviews();
              handleOpenMenu();
            }}
          >
            review
          </li>
          <div
            className='hover:cursor-pointer'
            onClick={() => {
              goApplication();
              handleOpenMenu();
            }}
          >
            1:1 coaching
          </div>
          <li
            className='hover:cursor-pointer'
            onClick={() => {
              goProgram();
              handleOpenMenu();
            }}
          >
            program
          </li>
          <li
            className='hover:cursor-pointer'
            onClick={() => {
              goFaq();
              handleOpenMenu();
            }}
          >
            faq
          </li>
        </ul>
      )}
    </div>
  );
};

export default Nav;
