import { useRef } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const baseUrl = 'https://public-file.wenow.me/';

const Story = ({ title, description, background }) => {
  return (
    <div className='snap-always snap-center w-full shrink-0 h-svh p-4 sm:p-8'>
      <div
        className={`px-3 py-2 flex flex-col justify-start items-center h-full bg-white gap-2 pt-20 rounded-xl`}
        style={{
          backgroundImage: `url(${baseUrl}${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
        }}
      >
        <div className='font-["Anton"] text-[#626c39] font-medium text-3xl'>
          {title}
        </div>
        <div className='font-["Roboto"] text-[#8c9a50] px-4 text-center leading-snug'>
          {description}
        </div>
      </div>
    </div>
  );
};

const AboutSection = () => {
  const horizontalRef = useRef(null);

  const handleNext = () => {
    horizontalRef.current.scrollBy({ left: 320, behavior: 'smooth' });
  };

  const handlePrev = () => {
    horizontalRef.current.scrollBy({ left: -320, behavior: 'smooth' });
  };

  return (
    <section id='about'>
      <div className='relative bg-[#626c39]'>
        <div
          ref={horizontalRef}
          className={`flex gap-8 snap-x overflow-x-scroll scrollbar-hidden snap-mandatory`}
        >
          <Story
            title='Healthy Happiness'
            description='True happiness blossoms when we prioritize our health; a healthy body nurtures a joyful spirit.'
            background='davidpark/about/about-1.png'
          />
          <Story
            title='Mindful Development'
            description='A positive mind fuels growth; embrace learning, and watch yourself transform into the best version of you.'
            background='davidpark/about/about-2.png'
          />
          <Story
            title='Consistent Growth'
            description='Success is built on the foundation of habit and consistency; small, daily actions lead to extraordinary results.'
            background='davidpark/about/about-3.png'
          />
        </div>
        <div className='absolute top-1/2 text-[#e5eccc]'>
          <ChevronLeftIcon
            className='size-4 sm:size-8 sm:stroke-2 hover:cursor-pointer'
            onClick={handlePrev}
          />
        </div>
        <div className='absolute top-1/2 right-0 text-[#e5eccc]'>
          <ChevronRightIcon
            className='size-4 sm:size-8 sm:stroke-2 hover:cursor-pointer'
            onClick={handleNext}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
