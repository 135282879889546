import { ChevronRightIcon } from '@heroicons/react/24/outline';
// import {
//   XCircleIcon,
//   PaperAirplaneIcon,
//   ArrowRightIcon,
// } from '@heroicons/react/20/solid';
import { useState } from 'react';

const faqs = [
  {
    question: 'What can I expect from a 1-on-1 online coaching program?',
    answer:
      'You’ll receive personalized workout and nutrition plans tailored to your specific goals, regular check-ins for progress, and ongoing support through direct communication. Each session is designed to keep you focused, motivated, and accountable.',
  },
  {
    question: 'How does online coaching work if we’re in different locations?',
    answer:
      'Online coaching provides flexibility, as you can train wherever you are! I’ll deliver all workouts, feedback, and resources through an app or platform, and we’ll communicate regularly through chat or video calls to ensure you stay on track.',
  },
  {
    question: 'Will the program be customized for my fitness level?',
    answer:
      'Absolutely! Every plan is tailored to your unique fitness level, lifestyle, and goals. Whether you’re a beginner or advanced, the workouts and guidance will be personalized to ensure effective and enjoyable progress.',
  },
  {
    question:
      'How often do we communicate, and what kind of support is included?',
    answer:
      'We’ll have a call feedback session every two weeks to discuss your progress, adjust plans, and answer any questions. In between calls, I’m available for ongoing support through chat or message to provide guidance on workouts, nutrition, and any adjustments needed along the way.',
  },
  {
    question: 'What equipment will I need for the program?',
    answer:
      'I can customize your program based on the equipment you have, whether it’s just body weight, resistance bands, or a full gym setup. I’ll make sure the workouts fit your available resources.',
  },
];

const Card = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className='rounded-xl px-3 py-2 bg-[#7c8949]'>
      <div
        className='flex items-center justify-between gap-2'
        onClick={toggleOpen}
      >
        <h2 className='font-semibold text-lg flex-1 leading-tight'>
          {question}
        </h2>
        <ChevronRightIcon
          className={`size-5 transition-transform duration-250 ${
            open ? 'rotate-90' : ''
          }`}
        />
      </div>
      <p
        className={`opacity-75 overflow-hidden transition-height duration-500 leading-tight ${
          open ? 'pt-2 h-fit' : 'h-0'
        }`}
      >
        {answer}
      </p>
    </div>
  );
};

const FAQSection = () => {
  // const dialogRef = useRef(null);

  // const [email, setEmail] = useState('');
  // const [message, setMessage] = useState('');

  // const openDialog = () => {
  //   dialogRef.current.showModal();
  //   document.body.classList.add('overflow-hidden');
  // };

  // const closeDialog = () => {
  //   dialogRef.current.close();
  //   document.body.classList.remove('overflow-hidden');
  // };

  return (
    <section
      id='faq'
      className='bg-[#626c39]'
    >
      <h1 className='mb-6 text-2xl font-["Anton"] text-white px-4 pt-4 sm:px-8 sm:pt-8'>
        FAQ
      </h1>
      <div className='text-white space-y-4 px-4 sm:px-8'>
        {faqs.map((faq) => (
          <Card
            key={faq.question}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div>
      {/* <div className='text-white p-4 space-y-4 mt-6'>
        <p>
          Have more questions? Reach out to me directly with message. Click here
          to send.
        </p>
        <button
          className='px-3 py-1 rounded-xl flex items-center gap-2 bg-[#e5eccc] text-[#626c39] font-bold'
          onClick={openDialog}
        >
          Send Message <ArrowRightIcon className='size-5' />
        </button>
      </div> */}
      {/* <dialog
        ref={dialogRef}
        className='bg-white rounded-xl w-full'
        onCancel={() => document.body.classList.remove('overflow-hidden')}
      >
        <div className='p-4'>
          <div className='mb-4 flex items-center justify-between'>
            <h2 className='font-semibold text-[#626c39]'>Message</h2>
            <div>
              <XCircleIcon
                className='size-5'
                onClick={closeDialog}
              />
            </div>
          </div>
          <div className='space-y-2'>
            <div>
              <label className='text-xs font-semibold pl-1'>Email</label>
              <input
                type='email'
                className='w-full bg-[#e5eccc] rounded-xl p-2 placeholder:italic'
                placeholder='Type your email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label className='text-xs font-semibold pl-1'>Message</label>
              <textarea
                className='w-full bg-[#e5eccc] rounded-xl p-2 resize-none placeholder:italic'
                rows={5}
                placeholder='Type your message here'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <button
              className='px-3 py-1 rounded-xl flex items-center gap-2 bg-[#626c39] text-[#e5eccc] font-bold'
              onClick={closeDialog}
            >
              Send <PaperAirplaneIcon className='size-5' />
            </button>
          </div>
        </div>
      </dialog> */}
    </section>
  );
};

export default FAQSection;
